import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby";
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';

const Cennik = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/las1.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
      }
      mobileImage: file(relativePath: { eq: "images/photos/las1-mobile.jpg" }) {
        childImageSharp {
            fluid(quality: 50){
            ...GatsbyImageSharpFluid
            }
        }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section id="section6">
          <div className="content-container prices">
            <h1>PREISE</h1>

            <div style={{ marginBottom: 50 }}>

            <table style={{ marginBottom: 50 }}>
                <tr>
                  <th></th>
                  <th>Amber 1</th>
                  <th>Amber 2</th>
                  <th>Amber 3</th>
                </tr>
                <tr>
                  <td><b>NIEDRIGE SAISON</b></td>
                </tr>
                <tr>
                  <td>1.10 - 30.05</td>
                  <td>200 zł - 220 zł</td>
                  <td>180 zł - 200 zł</td>
                  <td>250 zł - 300 zł</td>
                </tr>
                <tr>
                  <td>1-30.06 i 1-30.09</td>
                  <td>250 zł - 300 zł</td>
                  <td>250 zł - 280 zł</td>
                  <td>350 zł - 450 zł</td>
                </tr>
                <tr>
                  <td><b>HOHE SAISON</b></td>
                </tr>
                <tr>
                  <td>1.07-30.08</td>
                  <td>400 zł - 500 zł</td>
                  <td>350 zł - 450 zł</td>
                  <td>600 zł - 800 zł</td>
                </tr>
                <tr>
                  <td>Maianfang, Ostern, Weihnachten, Silvester</td>
                  <td>280 zł - 450 zł</td>
                  <td>250 zł - 400 zł</td>
                  <td>350 zł - 600 zł</td>
                </tr>
              </table>
            </div>
            <span className='divider'/>

<h2>zusätzliche Informationen und Reservierung:</h2>
<ul style={{ marginBottom: 50 }}>
  <li><FaCheck />Anmeldung: ab 16 Uhr, Ausmeldung : bis 10 Uhr</li>
  <li><FaCheck />Preise für Aufräumen - obligatorisch:
    <ul>
      <li><FaCheck />Amber 1 und Amber 2 – 200 zł</li>
      <li><FaCheck />Amber 3 - 250 zł</li>
    </ul>
  </li>

  <li><FaCheck />Klimagebühr 3 PLN/pro Person/pro Tag</li>
  <li><FaCheck />Bei Anmeldung zahlen sie auch eine Rückkaution:
  <ul>
      <li><FaCheck />Amber 1 und Amber 2 – 300 zł</li>
      <li><FaCheck />Amber 3 - 500 zł</li>
    </ul>
  </li>
  <li><FaCheck />Bei Reservation zahlen Sie 30% innerhalb 3 Tagen auf Bankkonto:
    <ul>
      <li><FaCheck />45102039580000970202595452 Bank PKO BP SA</li>
      <li><FaCheck />Wir geben eine Rechnung.</li>
    </ul>
  </li>
  <li><FaCheck />Weiter zahlen Sie bei Anmeldung in bar oder auf diese Bankkonto ein Tag vor der Anmeldung</li>
  <li><FaCheck />in niedriger Saison mindestens 2 Tage</li>
  <li><FaCheck />in hoher Saison mindestens 7 Tage</li>
</ul>

<span className='divider'/>
          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default Cennik
